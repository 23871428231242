var Header = (function() {
	'use strict';

	var menuNavGroupWrapperIdentifier = 0;
	var menuNavGroupWrapperList = {};

	function Header(node) {
		// enforces new
		if (!(this instanceof Header)) {
			return new Header(node);
		}
		
		this.node = node;
		this.menu = this.node.querySelector('.Header-menu');
		this.burgerButton = this.node.querySelector('.Header-burger-button');
		this.menuWrapper = this.node.querySelector('.Header-menu-nav-inner-wrapper');
		this.menuIsOpen = false;

		var self = this;
		this.menuNavGroup_list = this.node.querySelectorAll('.Header-menu-nav-group, .Header-menu-nav-group-link-to-member-zone');
		this.menuNavGroupLinkList_list = [];

		dom.forEach(this.menuNavGroup_list, function (menuNavGroup) {
			var menuNavGroupLinkList = menuNavGroup.querySelector('.Header-menu-nav-group-link-list');

			if (menuNavGroupLinkList) {
				var menuNavGroupLinkLabel = menuNavGroup.querySelector('.Header-menu-nav-group-label');
				var menuNavGroupLinkCloseButton = menuNavGroup.querySelector('.Header-menu-nav-group-close-list-button');
				dom.setData(menuNavGroupLinkLabel, 'menuNavGroupWrapperIdentifier', "_"+(menuNavGroupWrapperIdentifier++));
				dom.setData(menuNavGroupLinkCloseButton, 'menuNavGroupWrapperIdentifier', dom.getData(menuNavGroupLinkLabel, 'menuNavGroupWrapperIdentifier'));

				var menuNavGroupWrapper = {
					element : menuNavGroupLinkList,
					parent : menuNavGroup,
					identifier : dom.getData(menuNavGroupLinkLabel, 'menuNavGroupWrapperIdentifier')
				};
				
				menuNavGroupWrapperList[dom.getData(menuNavGroupLinkLabel, 'menuNavGroupWrapperIdentifier')] = menuNavGroupWrapper;

				self.menuNavGroupLinkList_list.push(menuNavGroupWrapper);
			}
		});
	}

	Header.getDependencyList = function() {
		return [
			'frameManager'
		];
	};

	Header.getEventListenerList = function() {
		return [
			"burgerButton.click(blur, preventDefault) -> toggleMenu",
			"[Header-menu-nav-group-label].click() -> toggleNavGroup",
			"[Header-menu-nav-group-close-list-button].click(preventDefault) -> closeNavGroup"
		];
	};

	Header.prototype.init = function() {
		this.frameManager.addResizeListener(this);

		return this;
	};

	Header.prototype.toggleNavGroup = function(event) {
		var menuNavGroupWrapperIdentifier = dom.getData(event.target, 'menuNavGroupWrapperIdentifier');

		if (menuNavGroupWrapperIdentifier) {
			var menuNavGroupWrapper = menuNavGroupWrapperList[menuNavGroupWrapperIdentifier];

			_.forEach(this.menuNavGroupLinkList_list, function (menuNavGroupLinkList) {
				if (menuNavGroupLinkList.identifier !== menuNavGroupWrapperIdentifier) {
					dom.removeClass(menuNavGroupLinkList.element, 'opened');
				}
			});

			if (!app.deviceManager.is('desktop')){
				event.preventDefault();
				dom.toggleClass(menuNavGroupWrapper.element, "opened");
			}

			dom[dom.hasClass(menuNavGroupWrapper.element, 'opened') ? 'addClass' : 'removeClass'](this.node, 'menu-nav-group-link-list-is-open');
		}
	};

	Header.prototype.closeNavGroup = function(event) {
		var menuNavGroupWrapperIdentifier = dom.getData(event.target, 'menuNavGroupWrapperIdentifier');
		var menuNavGroupWrapper = menuNavGroupWrapperList[menuNavGroupWrapperIdentifier];
		dom.removeClass(menuNavGroupWrapper.element, "opened");
	};

	Header.prototype.resize = function() {
		this.adjustMenuNavGroupLinkListWidth();
		this.setMenuNavGroupLinkListPosition();

		if (app.deviceManager.is('desktop')) {
			_.forEach(menuNavGroupWrapperList, function (groupList) {
				dom.removeClass(groupList.element, "opened");
			});
		}
	};

	Header.prototype.setMenuNavGroupLinkListPosition = function() {
		_.forEach(this.menuNavGroupLinkList_list, function (menuNavGroupLinkList) {
			var linkListNode = menuNavGroupLinkList.element;
			var linkListParent = menuNavGroupLinkList.parent;

			var offset = (dom.getWidth(linkListParent) - dom.getWidth(linkListNode))/2;
			linkListNode.style.left = offset+'px';
		});
	};

	Header.prototype.adjustMenuNavGroupLinkListWidth = function() {
		_.forEach(this.menuNavGroupLinkList_list, function (menuNavGroupLinkList) {
			var linkListNode = menuNavGroupLinkList.element;
			linkListNode.style.width = null;

			if (app.deviceManager.is('desktop')) {
				var linkListStyle = dom.getStyle(linkListNode);
				linkListNode.style.width = (30+dom.getWidth(linkListNode)-(parseInt(linkListStyle["padding-left"], 10)+parseInt(linkListStyle["padding-right"], 10)))+"px";
			}
		});
	};

	Header.prototype.toggleMenu = function() {
		this.menuIsOpen ? this.closeMenu() : this.openMenu();
	};

	Header.prototype.closeMenu = function() {
		this.menuIsOpen = false;

		dom.removeClass(this.burgerButton, 'open');
		dom.removeClass(this.menuWrapper, 'opened');
		dom.removeClass(this.node, 'menu-is-open');
		dom.removeClass(this.node, 'menu-nav-group-link-list-is-open');

		_.forEach(this.menuNavGroupLinkList_list, function (menuNavGroupLinkList) {
			dom.removeClass(menuNavGroupLinkList.element, 'opened');
		});
	};

	Header.prototype.getCurrentMenuHeight = function() {
		var menuStyle = dom.getStyle(this.menu);
		return parseInt(menuStyle.height, 10) + parseInt(menuStyle['border-bottom-width'], 10);
	};

	Header.prototype.openMenu = function() {
		this.menuIsOpen = true;
		dom.addClass(this.burgerButton, 'open');
		dom.addClass(this.menuWrapper, 'opened');
		dom.addClass(this.node, 'menu-is-open');

		dom.removeClass(this.node, 'menu-nav-group-link-list-is-open');

		_.forEach(this.menuNavGroupLinkList_list, function (menuNavGroupLinkList) {
			dom.removeClass(menuNavGroupLinkList.element, 'opened');
		});
	};

	return Header;
}());