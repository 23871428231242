getScriptManager().registerPageScript('home', function (page) {
	app.componentList.scrollLandingPage = factory.create.component.init(
		ScrollLandingPage,
		page.querySelector('.ScrollLandingPage')
	);

	/*Carousel*/

	app.componentList.homeHeaderCarousel = factory.create.component.init(
		Carousel,
		page.querySelector('.Section-home_full-header-carousel')
	);

	synchronizeCarouselAndNodeList(
		app.componentList.homeHeaderCarousel,
		page.querySelectorAll('.Article_home_header_list_element')
	);

	var articleList = page.querySelector('.Section-home_full-header-article-list-outer-wrapper');
	var carouselInnerWrapper = app.componentList.homeHeaderCarousel.innerWrapper;

	app.frameManager.addResizeListener({
		resize:function (frame) {
			var currentListHeight = parseInt(dom.getStyle(articleList).height, 10);

			carouselInnerWrapper.style.height = (frame.size.height - currentListHeight)+'px';
		}
	});

	/*=========*/

	dom.selectOne('.ui-scroll-down-picto').addEventListener('click', function (event) {
		event.preventDefault();
		app.behaviour.scrollDownAnimation()
	});
});

function synchronizeCarouselAndNodeList (carousel, nodeList) {
	dom.addClass(nodeList[carousel.currentIndex], 'current');
	carousel.addMoveListener({
		carouselMove : function (move) {
			dom.forEach(nodeList, function (node, index) {
				dom[index === move.to ? 'addClass' : 'removeClass'](node, 'current')
			})
		}
	});
}