var ScrollTopButton = (function() {
	'use strict';

	function ScrollTopButton(node) {
		// enforces new
		if (!(this instanceof ScrollTopButton)) {
			return new ScrollTopButton(node);
		}
		this.node = node;
	}

	ScrollTopButton.getDependencyList = function() {
		return [
			'animationManager',
			'scrollManager'
		];
	};

	ScrollTopButton.getEventListenerList = function() {
		return [
			"node.click(blur, preventDefault) -> scrollTop"
		];
	};

	ScrollTopButton.prototype.init = function() {
		return this;
	};

	ScrollTopButton.prototype.scrollTop = function() {
		this.animationManager.tween({
			identifier : "scroll-top-animation",
			duration : 500,
			startValue : this.scrollManager.getScrollTop(),
			endValue : 0,
			easing: "inOutQuart"
		}, function (tween) {
			app.scrollManager.scrollTo(tween.currentValue);
		});
	};

	return ScrollTopButton;
}());