var Carousel = (function() {
	'use strict';

	function Carousel(node) {
		// enforces new
		if (!(this instanceof Carousel)) {
			return new Carousel(node);
		}
		
		this.node = node;

		this.innerWrapper = this.node.querySelector(".Carousel-inner-wrapper");
		this.cellNodeList = this.innerWrapper.querySelectorAll('.CarouselCell');

		this.nextButton = dom.select('a.Carousel-next-button[data-carousel-target-id="'+this.node.id+'"]');
		this.previousButton = dom.select('a.Carousel-previous-button[data-carousel-target-id="'+this.node.id+'"]');
		
		this.moveListenerList = [];
	}

	Carousel.getEventListenerList = function() {
		return [
			"nextButton.click(blur, preventDefault) -> moveToNext",
			"previousButton.click(blur, preventDefault) -> moveToPrevious"
		];
	};

	Carousel.getDataPropertyList = function() {
		return {
			startIndex : 0,
			animated : true,
			loop : false,
			auto : false,
			autoDelay : 5000
		};
	};

	Carousel.getDependencyList = function() {
		return [
			'timeManager'
		];
	};

	Carousel.prototype.init = function() {
		var self = this;
		this.cellList = [];

		this.currentIndex = 0;
		this.maxIndex = this.cellNodeList.length - 1;

		dom.forEach(this.cellNodeList, function (cellNode, cellIndex) {
			var cell = factory.create.component(CarouselCell, cellNode);

			cell.index = cellIndex;

			self.cellList.push(cell.init());
		});

		if (this.startIndex === "random") {
			this.startIndex = _.random(0, this.maxIndex);
		}
		
		this.moveTo(this.startIndex);
		
		this.getCurrentCell().setActive(true);

		this.autoTimeLoop = this.timeManager.addTimeLoop(this.autoDelay, function () {
			if (self.auto) {
				self.moveToNext();
			}
		});

		requestAnimationFrame(function () {
			self.setAnimated(self.animated);
		});

		return this;
	};

	Carousel.prototype.addMoveListener = function(listener) {
		if (!_.isFunction(listener.carouselMove)) {
			throw new Error('A move listener for a Carousel must have a method carouselMove')
		};
		
		this.moveListenerList.push(listener);
	};

	Carousel.prototype.moveEvent = function(move) {
		_.forEach(this.moveListenerList, function (listener) {
			listener.carouselMove(move);
		});
	};

	Carousel.prototype.moveTo = function(index) {
		var targetIndex = index > this.maxIndex ? (this.loop ? 0 : this.maxIndex) : (index < 0 ? (this.loop ? this.maxIndex : 0) : index);

		if (targetIndex !== this.currentIndex) {
			this.getCurrentCell().setActive(false);

			var cell = this.cellList[targetIndex];
			cell.setActive(true);
			this.setOffset("-"+cell.getLeftPosition());

			this.moveEvent({
				from : this.currentIndex,
				to : targetIndex
			});
		}

		this.currentIndex = targetIndex;
	};

	Carousel.prototype.getCurrentCell = function() {
		return this.cellList[this.currentIndex];
	};

	Carousel.prototype.moveToNext = function() {
		this.autoTimeLoop.time = 0;
		this.moveTo(this.currentIndex+1);
	};

	Carousel.prototype.moveToPrevious = function() {
		this.autoTimeLoop.time = 0;
		this.moveTo(this.currentIndex-1);
	};

	Carousel.prototype.setOffset = function(offset) {
		this.innerWrapper.style.left = offset;
	};

	Carousel.prototype.setAnimated = function(animated) {
		dom[animated === true ? 'addClass' : 'removeClass'](this.node, 'animated');
	};

	return Carousel;
}());