var DeviceManager = (function() {
	'use strict';

	function DeviceManager(params) {
		// enforces new
		if (!(this instanceof DeviceManager)) {
			return new DeviceManager(params);
		}
		
		this.frameManager = params.frameManager;

		this.changeListenerList = [];

		this.deviceDefinitionMarker = "";

		this.init();
	}

	DeviceManager.defaultDeviceList = ['desktop', 'tablet', 'mobile'];
	DeviceManager.defaultDevice = 'desktop';

	DeviceManager.prototype.init = function() {
		this.deviceRetinaFrame = dom.createDiv('device_DeviceManager_deviceRetinaFrame');
		this.deviceListFrame = dom.createDiv('device_DeviceManager_deviceListFrame');
		this.currentDeviceCheckingFrame = dom.createDiv('device_DeviceManager_currentDeviceCheckingFrame');

		document.body.appendChild(this.deviceListFrame);
		document.body.appendChild(this.deviceRetinaFrame);
		document.body.appendChild(this.currentDeviceCheckingFrame);

		this.currentDeviceCheckingFrameInnerFrameList = {};

		this.update();

		this.frameManager.addResizeListener(this);

		return this;
	};

	DeviceManager.prototype.changeEvent = function() {
		var listenerList = this.changeListenerList;
		for(var i=0,imax=listenerList.length;i<imax;i++){
			listenerList[i].deviceChange(this);
		}
	};

	DeviceManager.prototype.addChangeListener = function(listener) {
		if (!_.isFunction(listener.deviceChange)) {
			throw new Error('A change listener for a DeviceManager must have a method deviceChange');
		};
		
		this.changeListenerList.push(listener);
	};

	DeviceManager.prototype.resize = function(){
		this.update();
	}

	DeviceManager.prototype.update = function() {
		this.deviceList = this.getDeviceList();
		this.currentDeviceList = this.getCurrentDeviceList();

		for(var i=0, imax = this.deviceList.length;i<imax;i++){
			var device = this.deviceList[i];
			if (!this.currentDeviceCheckingFrameInnerFrameList[device]) {
				this.currentDeviceCheckingFrame.appendChild(
					this.currentDeviceCheckingFrameInnerFrameList[device] = dom.createDiv('device_DeviceManager_currentDeviceCheckingFrame-'+device)
				);
			}
			
		}

		var deviceDefinitionMarker = "";
		for(var j=0, jmax=this.currentDeviceList.length;j<jmax;j++){
			deviceDefinitionMarker += "/"+this.currentDeviceList[j];
		}

		if (deviceDefinitionMarker !== this.deviceDefinitionMarker || deviceDefinitionMarker.indexOf('hotfix') > -1) {
			this.changeEvent();
			this.deviceDefinitionMarker = deviceDefinitionMarker;
		};
	};

	DeviceManager.prototype.getCurrentDeviceList = function() {
		var currentDeviceList = [];
		for(var deviceName in this.currentDeviceCheckingFrameInnerFrameList){
			var deviceInnerFrame = this.currentDeviceCheckingFrameInnerFrameList[deviceName];

			var deviceIsActive = (dom.getStyle(deviceInnerFrame).content.replace('"', "").replace('"', "") === "active" || ''+dom.getStyle(deviceInnerFrame).height.replace('"', "").replace('"', "") === "1px");
			if (deviceIsActive) {
				currentDeviceList.push(deviceName);
			}
		}

		currentDeviceList = currentDeviceList.length > 0 ? currentDeviceList : [DeviceManager.defaultDevice, 'hotfix'];

		return currentDeviceList;
	};

	DeviceManager.prototype.getDeviceList = function() {
		var deviceList = dom.getStyle(this.deviceListFrame).content.replace('"', "").replace('"', "").split(',');

		deviceList.pop();

		return deviceList = deviceList.length > 0 ? deviceList : DeviceManager.defaultDeviceList;
	};

	DeviceManager.prototype.is = function(deviceName) {
		return deviceName === "retina" ? this.isRetina() : _.contains(this.currentDeviceList, deviceName);
	};

	DeviceManager.prototype.isRetina = function() {
		return (dom.getStyle(this.deviceRetinaFrame).content.replace('"', "").replace('"', "") === "active" || ''+dom.getStyle(this.deviceRetinaFrame).height.replace('"', "").replace('"', "") === "1px");
	};

	return DeviceManager;
}());