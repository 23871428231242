var http = {}, ajax = {};

ajax.request = function (params) {
	params.json = true;
	return http.request(params);
};
http.request = function (params) {
	params.data = typeof params.data === "object" ? params.data : {};
	var postData = (_.map(params.data, function (value, key) {
		return key+"="+value;
	})).join('&');

	var request = new XMLHttpRequest();
	
	/*---------------*/
	var _reject = null;
	var requestRejectFunction = function (err) {
		if(typeof _reject === "function"){
			_reject(new Error("http error\n\t-request status: "+request.status+(err ? "\n\t"+err.message : "")), request.responseText);return 
		}
	}
	request.onerror = function() {
		requestRejectFunction();
	};
	/*---------------*/
	var _resolve = null;
	var requestResolveFunction = function () {
		if(typeof _resolve === "function"){
			if (request.status >= 200 && request.status < 400) {
				var data = request.responseText;

				try{
					_resolve(params.json ? JSON.parse(request.responseText) : request.responseText);
				}
				catch(err){
					err.message = "-json error: "+err.message;
					requestRejectFunction(err);
				}
			}
			else {
				requestRejectFunction();
			}
		}
	}
	request.onload = function() {
		requestResolveFunction();
	};
	/*--------------*/

	request.open(params.method, params.url, true);
	if (params.method === "POST") {
		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
		request.send(postData);
	}
	else{
		request.send();
	}

	var returnObject = {
		"then" : function (resolve) {
			_resolve = resolve;

			return returnObject;
		},
		"catch" : function (reject) {
			_reject = reject;

			return returnObject;
		}
	};

	return returnObject
}

ajax.post = function (params) {
	params.method = 'POST';
	return ajax.request(params);
};
http.post = function(params){
	params.method = 'POST';
	return http.request(params);
};

ajax.get = function (params) {
	params.method = 'GET';
	return ajax.request(params);
};
http.get = function(params){
	params.method = 'GET';

	return http.request(params);
};