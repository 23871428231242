var Section_header_pop_in__override = Section_header_pop_in__override || {};

Section_header_pop_in__override.connexion = (function() {
	'use strict';

	function SectionConnexion_header_pop_in(node) {
		// enforces new
		if (!(this instanceof SectionConnexion_header_pop_in)) {
			return new SectionConnexion_header_pop_in(node);
		}

		this.node = node;

		this.openerButtonList = dom.select('[data-is-opener-for-pop-in="'+this.node.id+'"]');
		this.elementToHideList = dom.select('.SearchBar');

		this.submitButton = this.node.querySelector('.InputSubmit-input');

		this.form = this.node.querySelector('form');
		this.inputList = this.form.querySelectorAll('input, textarea');
		this.errorMessageList = this.form.querySelector('.form-error-message-list');

		this.isOpen = false;
	}

	SectionConnexion_header_pop_in.getEventListenerList = function() {
		return [
			"openerButtonList.click(blur, preventDefault) -> toggle",
			"submitButton.click(blur, preventDefault) -> submit"
		];
	};

	SectionConnexion_header_pop_in.prototype.getFormAction = function() {
		return this.form.getAttribute('data-ajax-action');
	};

	SectionConnexion_header_pop_in.prototype.init = function() {
		var inputList = {};
		dom.forEach(this.inputList, function (element) {
			inputList[element.name] = element;
		});
		this.inputList = inputList;

		return this;
	};

	SectionConnexion_header_pop_in.prototype.open = function() {
		dom.addClass(this.node, 'opened');
		dom.forEach(this.elementToHideList, function (toHide) {
			dom.addClass(toHide, 'hidden');
		});
		this.isOpen = true;
	};

	SectionConnexion_header_pop_in.prototype.close = function() {
		this.emptyErrorMessage();
		dom.removeClass(this.node, 'opened');
		dom.forEach(this.elementToHideList, function (toHide) {
			dom.removeClass(toHide, 'hidden');
		});
		this.isOpen = false;
	};

	SectionConnexion_header_pop_in.prototype.toggle = function() {
		this.isOpen ? this.close() : this.open();
	};

	SectionConnexion_header_pop_in.prototype.submit = function() {
		this.emptyErrorMessage();
		var self = this;
		var formData = this.formData = _.mapValues(this.inputList, function (element, key) {
			switch(element.type){
				case 'checkbox':
					return element.checked;
				break;
				default:
					return element.value;
				break;
			}
		});


		ajax.post({
			url: this.getFormAction(),
			data: formData
		}).then(function (data) {
			self.submitReturn(data);
		})['catch'](function (err, data) {
			self.submitError(err, data);
		});
	};

	SectionConnexion_header_pop_in.prototype.submitReturn = function(data) {
		if (data.connected) {
			window.location = this.formData.RedirectURI;
		}
		else{
			this.fillErrorMessage();
		}
	};

	SectionConnexion_header_pop_in.prototype.submitError = function(err, data) {
		this.fillErrorMessage();
	};

	SectionConnexion_header_pop_in.prototype.emptyErrorMessage = function() {
		this.errorMessageList.innerHTML = "";
	};

	SectionConnexion_header_pop_in.prototype.fillErrorMessage = function() {
		this.errorMessageList.innerHTML = "Merci de vérifier vos identifiants.";
	};

	return SectionConnexion_header_pop_in;
}());