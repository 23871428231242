var AnimationManager = (function() {
	'use strict';

	var tweenIdentifierCount = 0;

	function AnimationManager(params) {
		// enforces new
		if (!(this instanceof AnimationManager)) {
			return new AnimationManager(params);
		}
		
		this.timeManager = params.timeManager;

		this.tweenList = {};
	}

	AnimationManager.prototype.init = function() {
		this.timeManager.addTimeUpdateListener(this);

		return this;
	};

	AnimationManager.prototype.timeUpdate = function(time) {
		var endedTween = [], self = this;
		_.forEach(this.tweenList, function (tween) {
			tween.currentTime+=time.delta;

			tween.cursor = tween.currentTime/tween.duration;
			
			if (tween.currentTime >= tween.duration) {
				endedTween.push(tween.identifier);
				tween.cursor = 1;
			}

			tween.easeFactor = tween.easingFunction(tween.cursor);
			tween.currentValue = tween.startValue + (tween.interval*tween.easeFactor);

			tween.updateBlock(tween);
		});

		_.forEach(endedTween, function (tweenIdentifier) {
			delete self.tweenList[tweenIdentifier];
		})
	};

	AnimationManager.prototype.tween = function(params, updateBlock) {
		var tweenIdentifier = params.identifier || this.newTweenIdentifier();

		var tween = {
			duration : params.duration,
			startValue : params.startValue,
			endValue : params.endValue,
			easingFunction : ease[params.easing],
			updateBlock : updateBlock,
			currentTime : 0,
			cursor : 0,
			currentValue : params.startValue,
			interval : params.endValue-params.startValue,
			identifier : tweenIdentifier
		};

		tween.easeFactor = tween.easingFunction(tween.cursor);

		this.tweenList[tweenIdentifier] = tween;
	};

	AnimationManager.prototype.newTweenIdentifier = function () {
		var tweenIdentifier = "animation_manager_tween_"+tweenIdentifierCount++;
		return this.tweenList[tweenIdentifier] ? this.newTweenIdentifier() : tweenIdentifier;
	}

	return AnimationManager;
}());