var ScrollLandingPage = (function() {
	'use strict';

	function ScrollLandingPage(node) {
		// enforces new
		if (!(this instanceof ScrollLandingPage)) {
			return new ScrollLandingPage(node);
		}
		
		this.node = node;

		this.stateChangeEventList = [];
		this.state = {
			onTop : null
		};

		this.previousState = {
			onTop : null
		};
	}

	ScrollLandingPage.getDataPropertyList = function() {
		return {
			scrollTopLimit : 1
		};
	};

	ScrollLandingPage.getDependencyList = function() {
		return [
			'frameManager',
			'scrollManager'
		];
	};

	ScrollLandingPage.prototype.init = function() {
		var self = this;

		this.frameManager.addResizeListener(this);

		this.scrollManager.addScrollChangeListener(this);

		return this;
	};

	ScrollLandingPage.prototype.resize = function(rect) {
		this.node.style.height = rect.size.height+"px";

		this.stateChangeEvent();
	};

	ScrollLandingPage.prototype.scrollChange = function(scroll) {
		this.previousState.onTop = this.state.onTop;

		this.state.onTop = !(scroll.position >= this.scrollTopLimit);

		if (this.state.onTop !== this.previousState.onTop) {
			this.stateChangeEvent();
		}
	};

	ScrollLandingPage.prototype.addStateChangeEvent = function(block) {
		this.stateChangeEventList.push(block);
	};

	ScrollLandingPage.prototype.stateChangeEvent = function() {
		var self = this;

		dom[this.state.onTop ? 'addClass' : 'removeClass'](this.node, 'on-top');

		_.forEach(this.stateChangeEventList, function (block) {
			block(self.state);
		});
	};

	return ScrollLandingPage;
}());