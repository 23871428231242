var CarouselCell = (function() {
	'use strict';

	function CarouselCell(node) {
		// enforces new
		if (!(this instanceof CarouselCell)) {
			return new CarouselCell(node);
		}
		
		this.node = node;
	}

	CarouselCell.prototype.init = function() {
		this.node.style.left = this.getLeftPosition();

		return this;
	};

	CarouselCell.prototype.getLeftPosition = function() {
		return CarouselCell.getLeftPositionForIndex(this.index);
	};

	CarouselCell.prototype.setActive = function(active) {
		dom[active === true ? 'addClass' : 'removeClass'](this.node, 'active');
	};

	CarouselCell.getLeftPositionForIndex = function (cellIndex) {
		return 100*(cellIndex || 0)+"%";
	};

	return CarouselCell;
}());