var dom = (function() {
	'use strict';

	var dom = {};

	dom.ready = function domReady(callback) {
		if (document.readyState != 'loading'){
			callback();
		}
		else {
			document.addEventListener('DOMContentLoaded', callback);
		}
	};

	dom.getData = function domGetData(node, key) {
		var dataValue = typeof node.dataset === 'object' ? node.dataset[key] : (function () {
			return node.getAttribute(_.kebabCase('data-'+key));
		})();

		return dataValue;
	};

	dom.setData = function domSetData(node, key, value) {
		typeof node.dataset === 'object' ? node.dataset[key] = value : (function () {
			node.setAttribute(_.kebabCase('data-'+key), value);
		})();
	};

	dom.selectById = function domSelectById(id) {
		return document.getElementById(id);
	};

	dom.select = function domSelect(selector) {
		return document.querySelectorAll(selector);
	};

	dom.selectOne = function domSelectOne(selector) {
		return dom.select(selector)[0];
	};

	dom.forEach = function domForEach(nodeList, block) {
		for(var i = 0, imax = nodeList.length;i<imax;i++){
			block(nodeList.item(i), i);
		}
	};

	dom.getStyle = function domGetStyle(element) {
		return typeof window.getComputedStyle === 'function' ? window.getComputedStyle(element) : {
			content : ""
		};
	};

	dom.getWidth = function domGetWidth(element) {
		return element.clientWidth || element.innerWidth;
	};

	dom.getHeight = function domGetHeight(element) {
		return element.clientHeight || element.innerHeight;
	};

	dom.createNode = function domCreateContainer(tag, classList) {
		var classList = typeof classList === "string" ? [classList] : classList,

			node = document.createElement(tag);

		for(var i=0, imax=classList.length;i<imax;i++){
			dom.addClass(node, classList[i]);
		}

		return node;
	};

	dom.createDiv = function domCreateDiv(classList) {
		return dom.createNode('div', classList);
	};

	dom.createSpan = function domCreateDiv(classList) {
		return dom.createNode('span', classList);
	};

	dom.addClass = function (element, className) {
		element.classList ? element.classList.add(className) : (element.className += ' ' + className);
	};

	dom.removeClass = function (element, className) {
		element.classList ? element.classList.remove(className) : (element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' '));
	};

	dom.hasClass = function (element, className) {
		return element.classList ? element.classList.contains(className) : (function () {
			return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
		})();
	}

	dom.toggleClass = function (element, className) {
		dom[dom.hasClass(element, className) ? "removeClass" : "addClass"](element, className);
	};

	dom.prependChild = function (parent, child) {
		parent.insertBefore(child, parent.firstChild);
	};
	
	return dom;
}());