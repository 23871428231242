window.app.main = function main () {
	app.componentList = {};

	app.timeManager = new TimeManager().init();

	app.frameManager = new FrameManager({
		frame : window,
		timeManager : app.timeManager
	}).init();

	app.deviceManager = new DeviceManager({
		frameManager : app.frameManager
	}).init();

	app.scrollManager = new ScrollManager({
		frameManager : app.frameManager,
		timeManager : app.timeManager,
		document : document,
		window : window
	}).init();

	app.animationManager = new AnimationManager({
		timeManager : app.timeManager
	}).init();

	app.historyManager = window.history ? window.history : {go:function (index) {
	}};

	factory.setDependency('timeManager', app.timeManager);
	factory.setDependency('frameManager', app.frameManager);
	factory.setDependency('deviceManager', app.deviceManager);
	factory.setDependency('scrollManager', app.scrollManager);
	factory.setDependency('animationManager', app.animationManager);
	factory.setDependency('historyManager', app.historyManager);

	/*---------------------------------*/

	app.behaviour = {};

	app.behaviour.scrollDownAnimation = function () {
		app.animationManager.tween({
			identifier : "home-scroll-down-animation",
			duration : 500,
			startValue : app.scrollManager.getScrollTop(),
			endValue : app.frameManager.getHeight() - app.componentList.header.getCurrentMenuHeight(),
			easing: "inOutQuart"
		}, function (tween) {
			app.scrollManager.scrollTo(tween.currentValue);
		});
	};

	/*---------------------------------*/

	app.imagePreloader = new ImagePreloader({
		useDefaultRetina: false
	});

	app.imagePreloader.registerDefaultImageList();

	app.imagePreloader.load()
	
	.then(function () {
		app.componentList.header = factory.create.component.init(
			Header,
			dom.selectOne('.Header')
		);

		app.componentList.connexionPopIn = factory.forEach(dom.select('.Section-connexion_header_pop_in')).create.component.init(
			Section_header_pop_in__override.connexion
		);

		app.componentList.scrollTopButton = factory.forEach(dom.select('.ScrollTopButton'))
		.create.component.init(
			ScrollTopButton
		);

		var searchBarNode = dom.selectOne('.SearchBar');
		if (searchBarNode) {
			app.componentList.searchBar = factory.create.component.init(
				SearchBar,
				searchBarNode
			);
		}

		app.scriptManager.executeAll();

		app.frameManager.resizeEvent();
		app.scrollManager.scrollEvent();

		/*Gestion des boutons d'impression*/
		dom.forEach(dom.select('.ToolButton.print'), function (toolButtonPrint) {
			toolButtonPrint.addEventListener('click', function (event) {
				event.preventDefault();
				window.print();
			});
		});

		initTableMobileHeader();

		dirtyFix();
	});
};

function initTableMobileHeader () {
	var tableList = dom.select('.Article_page_block-content table, .Article_main_block-main-content table');
	
	dom.forEach(tableList, function (element) {
		var thList = element.querySelectorAll('th'), numberOfTh = thList.length;

		if(numberOfTh){
			var tdList = element.querySelectorAll('td');
			var currentTh = 0;
			dom.forEach(tdList, function (td) {
				var mobileTh = dom.createSpan('mobile-th');
				mobileTh.innerHTML = thList[currentTh].innerHTML;
				dom.prependChild(td, mobileTh);
				currentTh++;
				if (currentTh >= numberOfTh) {currentTh=0};
			});
		}
	});
}

function dirtyFix() {
	/*buggy left floating element*/
	var buggyLeftFloatingElement = dom.select('.Article_block-vignette');
	var correctLeft = function () {
		dom.forEach(buggyLeftFloatingElement, function (leftFloating) {
			leftFloating.style.float = app.deviceManager.is('mobile') ? 'none' : 'left';
		});
	};
	app.deviceManager.addChangeListener({
		deviceChange:correctLeft
	});
	requestAnimationFrame(correctLeft);

	/*------------------------------*/

	/*Resize youtube embed video*/
	var resizeEmbedVideo = function (embedVideo, ratio) {
		var realWidth = dom.getWidth(embedVideo);
		var realHeight = realWidth/ratio;
		embedVideo.style.height = realHeight+'px';
	};

	var embedVideoList = dom.select('.youtube-embed-video');
	var embedVideoObjectList = [];
	dom.forEach(embedVideoList, function (embedVideo) {
		var ratio = embedVideo.width/embedVideo.height;
		embedVideoObjectList.push({
			ratio: ratio,
			node: embedVideo
		});
		embedVideo.width = "100%";
		resizeEmbedVideo(embedVideo, ratio);
	});

	app.frameManager.addResizeListener({
		resize: function (resize) {
			_.forEach(embedVideoObjectList, function (embedVideoObject) {
				resizeEmbedVideo(embedVideoObject.node, embedVideoObject.ratio);
			});
		}
	});

	/*-----------------------------*/

	/*add animated class on elements which are hidden at beginning*/
	/*the tricks is used to avoid the first disparition animation*/
	/*move the animated settings in javascript component classes would be better (using a setAnimated method for example)*/

	dom.forEach(dom.select('.Header, .Section-connexion_header_pop_in'), function (node) {
		requestAnimationFrame(function () {
			dom.addClass(node, 'animated');
		});
	});

	/*-----------------------------*/

	/*Article_related_list_element adjust height title*/
	var listElementTitleHeightAdjust = function () {
		dom.forEach(dom.select('.Article_full-related-article-list'), function (node) {
			var titleList = node.querySelectorAll('.Article_related_list_element-title-outer-wrapper');
			var styleValue = '';

			if (!app.deviceManager.is('mobile')) {
				var minHeight = 0;
				
				dom.forEach(titleList, function (title) {
					var titleHeight = dom.getHeight(title);
					minHeight = titleHeight > minHeight ? titleHeight : minHeight;
				});
				
				styleValue = 'min-height:'+minHeight+'px;';
			}

			dom.forEach(titleList, function (title) {
				title.setAttribute('style', styleValue);
			});
		});
	};
	app.deviceManager.addChangeListener({
		deviceChange: listElementTitleHeightAdjust
	});
	requestAnimationFrame(listElementTitleHeightAdjust);
}