var SearchBar = (function() {
	'use strict';

	function SearchBar(node) {
		// enforces new
		if (!(this instanceof SearchBar)) {
			return new SearchBar(node);
		}

		this.node = node;
		this.input = this.node.querySelector('.SearchBar-input');
		this.button = this.node.querySelector('.SearchBar-button');
	}

	SearchBar.getEventListenerList = function() {
		return [
			"button.click(blur, preventDefault) -> searchInputValue",
			"node.submit(preventDefault) -> searchInputValue"
		];
	};

	SearchBar.getDataPropertyList = function() {
		return {
			searchResultUrl : window.location
		};
	};

	SearchBar.prototype.init = function() {
		return this;
	};

	SearchBar.prototype.searchInputValue = function() {
		this.search(this.getInputValue());
	};

	SearchBar.prototype.getInputValue = function() {
		return _.trim(this.input.value);
	};

	SearchBar.prototype.search = function(value) {
		window.location = this.generateUrl({search:value});
	};

	SearchBar.prototype.generateUrl = function(params) {
		var params = params || {};
		var urlSep = "/";
		var urlSuffix = _.chain(params).map(function (value, key) {
			return ['('+key+')', value].join(urlSep);
		}).commit().value().join(urlSep);
		
		return [this.searchResultUrl, urlSuffix].join(urlSep);
	};

	return SearchBar;
}());