var Article_full = (function() {
	'use strict';

	function Article_full(node) {
		// enforces new
		if (!(this instanceof Article_full)) {
			return new Article_full(node);
		}
		this.node = node;
		this.previousPageButton = this.node.querySelector(".Article_full-previous-page-button");
	}

	Article_full.getDataPropertyList = function() {
		return {
			goToAnchor : "fds"
		};
	};

	Article_full.getDependencyList = function() {
		return [
			'historyManager'
		];
	};

	Article_full.getEventListenerList = function() {
		return [
			"previousPageButton.click(blur, preventDefault) -> goToPreviousPage"
		];
	};

	Article_full.prototype.init = function() {
		if (this.goToAnchor.length) {
			window.location = window.location+"#"+this.goToAnchor;
		}
		return this;
	};

	Article_full.prototype.goToPreviousPage = function() {
		this.historyManager.go(-1);
	};

	return Article_full;
}());