var ImagePreloader = (function() {
	'use strict';

	var preloadCount = 1;

	function ImagePreloader(params) {
		// enforces new
		if (!(this instanceof ImagePreloader)) {
			return new ImagePreloader(UILoaderComponent);
		}

		var params = typeof params === "object" ? params : {};

		this.useDefaultRetina = typeof params.useDefaultRetina === "boolean" ? params.useDefaultRetina : true;
		
		//this.UILoaderComponent = UILoaderComponent;

		this.indexedTarget = {};
	}

	ImagePreloader.prototype.init = function() {
		return this;
	};

	ImagePreloader.prototype.registerDefaultImageList = function() {
		this.registerDefaultBackgroundImageList();
	};

	ImagePreloader.prototype.registerDefaultBackgroundImageList = function() {
		var self = this;
		var backgroundImageToLoadList = dom.select('[data-image-preloader-background-image]');

		dom.forEach(backgroundImageToLoadList, function (backgroundImageToLoad, i) {
			var imageUrl = dom.getData(backgroundImageToLoad, 'imagePreloaderBackgroundImage');

			var tabletImageUrl = dom.getData(backgroundImageToLoad, 'imagePreloaderTabletBackgroundImage') || imageUrl;
			var mobileImageUrl = dom.getData(backgroundImageToLoad, 'imagePreloaderMobileBackgroundImage') || imageUrl;

			var imageUrlRetina = dom.getData(backgroundImageToLoad, 'imagePreloaderBackgroundImageRetina') || self.getRetinaDefaultUrl(imageUrl);
			var tabletImageUrlRetina = dom.getData(backgroundImageToLoad, 'imagePreloaderTabletBackgroundImageRetina') || self.getRetinaDefaultUrl(tabletImageUrl);
			var mobileImageUrlRetina = dom.getData(backgroundImageToLoad, 'imagePreloaderMobileBackgroundImageRetina') || self.getRetinaDefaultUrl(mobileImageUrl);

			/*if (tabletImageUrl === imageUrl || tabletImageUrl === mobileImageUrl || tabletImageUrl === mobileImageUrl) {
				console.log(backgroundImageToLoad.classList);
			}*/

			self.register({
				element: backgroundImageToLoad,
				imageUrl: imageUrl,
				tabletImageUrl: tabletImageUrl,
				mobileImageUrl: mobileImageUrl,
				imageUrlRetina: imageUrlRetina,
				tabletImageUrlRetina: tabletImageUrlRetina,
				mobileImageUrlRetina: mobileImageUrlRetina
			})
		});
	};

	ImagePreloader.prototype.register = function(targetToLoad) {
		var targetID = 'image-preloader-background-image-'+preloadCount;
		targetToLoad.ID = targetID;

		targetToLoad.imageUrl = ImagePreloader.getFullUrl(targetToLoad.imageUrl);
		targetToLoad.tabletImageUrl = ImagePreloader.getFullUrl(targetToLoad.tabletImageUrl);
		targetToLoad.mobileImageUrl = ImagePreloader.getFullUrl(targetToLoad.mobileImageUrl);

		this.indexedTarget[targetID] = targetToLoad;

		preloadCount++;
	};

	ImagePreloader.prototype.load = function() {
		for(var key in this.indexedTarget){
			ImagePreloader.setBackgroundImageForTarget(this.indexedTarget[key], /*forceMobile*/true, /*cancelRetina*/true);
		}

		app.deviceManager.addChangeListener(this);

		return {
			then : function (block) {
				if(typeof block === "function"){
					block();
				}
			}
		};
	};

	ImagePreloader.prototype.loadAndPerforms = function(block) {
		this.load().then(block);
	};

	ImagePreloader.prototype.deviceChange = function() {
		for(var key in this.indexedTarget){
			ImagePreloader.setBackgroundImageForTarget(this.indexedTarget[key]);
		}
	};

	ImagePreloader.setBackgroundImageForTarget = function (target, forceMobile, cancelRetina) {
		var useRetina = (app.deviceManager.is('retina') && !cancelRetina);
		if (app.deviceManager.is('mobile') || forceMobile) {
			ImagePreloader.setBackgroundImageForElement(target.element, 
				useRetina ? target.mobileImageUrlRetina : target.mobileImageUrl
			);
		}
		else if(app.deviceManager.is('tablet')){
			ImagePreloader.setBackgroundImageForElement(target.element, 
				useRetina ? target.tabletImageUrlRetina : target.tabletImageUrl
			);
		}
		else{
			ImagePreloader.setBackgroundImageForElement(target.element, 
				useRetina ? target.imageUrlRetina : target.imageUrl
			);
		}
	};

	ImagePreloader.prototype.getRetinaDefaultUrl = function (baseUrl) {
		if(!this.useDefaultRetina){
			return baseUrl;
		}

		var extname = path.extname(baseUrl);
		var retinaUrl = path.join(path.dirname(baseUrl), path.basename(baseUrl, extname)+"@2x"+extname);

		return retinaUrl;
	};

	ImagePreloader.setBackgroundImageForElement = function (element, imageUrl) {
		element.style.backgroundImage = 'url('+imageUrl+')';
	};

	ImagePreloader.getFullUrl = function (baseUrl) {
		return baseUrl.indexOf('http') === 0 ? baseUrl : (window.location.protocol+'//'+window.location.hostname+baseUrl);
	};

	return ImagePreloader;
}());